$white: #ffffff;
$black: #000000;
$primary1: #03a7d3;
$primary2: #ff4646;
$textCol: #999;

@mixin main-font {
  font-family: "Roboto", sans-serif;
}

@mixin hero_btn($col1, $col2, $pad1, $pad2, $bRadius) {
  display: inline-block;
  padding: $pad1 $pad2;
  background-color: $col1;
  color: $col2;
  border-radius: $bRadius;
  border: 1px solid $col1;
  transition: all .3s;

  &:hover {
    background-color: transparent;
    color: $col1;
  }
}

@mixin upperBold {
  text-transform: uppercase;
  font-weight: bold;
}

body {
  @include main-font;
  color: #0c0c0c;
  background-color: #f8f8f8;
  overflow-x: hidden;
}

.layout_padding {
  padding: 90px 0;
}

.layout_padding2 {
  padding: 75px 0;
}

.layout_padding2-top {
  padding-top: 75px;
}

.layout_padding2-bottom {
  padding-bottom: 75px;
}

.layout_padding-top {
  padding-top: 90px;
}

.layout_padding-bottom {
  padding-bottom: 90px;
}

.heading_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h2 {
    position: relative;
    font-weight: bold;
    margin: 0;

  }

  &.heading_center {
    align-items: center;
    text-align: center;
  }

  p {
    margin-top: 10px;
    margin-bottom: 0;
  }
}

.container_border_style {
  position: relative;
  padding: 20px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 3px;
    height: 90px;
    background-color: $primary1;
    transform: translateY(-50%);
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a:hover,
a:focus {
  color: initial;
}

.btn,
.btn:focus {
  outline: none !important;
  box-shadow: none;
}

/*header section*/
.hero_area {
  position: relative;
  background-color: #020230;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.sub_page {
  .hero_area {
    min-height: auto;
  }

}

.header_section {

  .container-fluid {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.navbar-brand {
  span {
    font-weight: bold;
    color: $white;
    font-size: 24px;
    text-transform: uppercase;
  }
}

.custom_nav-container {
  padding: 10px 0;

  .navbar-nav {
    .nav-item {
      .nav-link {
        padding: 3px 15px;
        margin: 10px 15px;
        color: $white;
        text-align: center;
        text-transform: uppercase;
      }

      // &:hover {
      //   .nav-link {}
      // }
    }
  }
}

.custom_nav-container .nav_search-btn {
  width: 35px;
  height: 35px;
  padding: 0;
  border: none;
  color: $white;
  margin: 0 20px;

  // &:hover {}
}

.custom_nav-container .navbar-toggler {
  outline: none;
}

.custom_nav-container .navbar-toggler {
  padding: 0;
  width: 37px;
  height: 42px;
  transition: all 0.3s;

  span {
    display: block;
    width: 35px;
    height: 4px;
    background-color: $white;
    margin: 7px 0;
    transition: all 0.3s;
    position: relative;
    border-radius: 5px;
    transition: all 0.3s;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $white;
      top: -10px;
      border-radius: 5px;
      transition: all 0.3s;
    }

    &::after {
      top: 10px;
    }
  }

  &[aria-expanded="true"] {
    transform: rotate(360deg);

    span {
      transform: rotate(45deg);

      &::before,
      &::after {
        transform: rotate(90deg);
        top: 0;
      }
    }

    .s-1 {
      transform: rotate(45deg);
      margin: 0;
      margin-bottom: -4px;
    }

    .s-2 {
      display: none;
    }

    .s-3 {
      transform: rotate(-45deg);
      margin: 0;
      margin-top: -4px;
    }
  }

  &[aria-expanded="false"] {

    .s-1,
    .s-2,
    .s-3 {
      transform: none;
    }
  }
}

.quote_btn-container {
  display: flex;
  align-items: center;

  a {
    color: $white;
    text-transform: uppercase;

    span {
      margin-left: 5px;
    }

    // &:hover {}
  }
}

/*end header section*/

/* slider section */
.slider_section {
  flex: 1;
  display: flex;
  align-items: center;
  padding-top: 45px;
  padding-bottom: 90px;


  .row {
    align-items: center;
  }

  #customCarousel1 {
    width: 100%;
    position: unset;
  }

  .detail-box {
    color: $white;

    h1 {
      font-weight: bold;
      margin-bottom: 25px;
      font-size: 2rem;

      span {
        font-size: 3rem;
        color: $primary1;
      }
    }

    .btn-box {
      display: flex;
      margin: 0 -5px;
    }

    a {
      margin: 5px;
      width: 195px;
      text-align: center;
    }

    .btn-1 {
      @include hero_btn($primary1, $white, 10px, 15px, 5px);
    }

    .btn-2 {
      @include hero_btn($primary2, $white, 10px, 15px, 5px);
    }
  }

  .img-box {
    img {
      width: 100%;
    }
  }

  .carousel_btn-box {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    z-index: 3;
    font-size: 22px;

    .carousel-control-prev,
    .carousel-control-next {
      position: unset;
      width: 50px;
      height: 50px;
      background-color: $primary1;
      opacity: 1;
      font-size: 28px;
      transition: all .3s;

      &:hover {
        background-color: $white;
        color: $black;
      }
    }
  }
}

// end slider section

// about section
.about_section {
  .row {
    align-items: center;
  }

  .img-box {
    img {
      max-width: 100%;
    }
  }

  .detail-box {
    p {
      color: $textCol;
      margin-top: 15px;
    }

    a {
      @include hero_btn($primary2, $white, 10px, 45px, 5px);
      margin-top: 15px;
    }
  }
}

// end about section

// service section

.service_section {
  position: relative;


  .box {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 25px;
    background-color: #fafafa;
    box-shadow: 0 0 5px 0 rgba($color: #000000, $alpha: .15);

    .img-box {
      width: 75px;
      height: 75px;
      background-color: $primary1;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all .2s;
      padding: 10px;

      img {
        width: 100%;
        max-width: 45px;
        max-height: 45px;
      }
    }

    .detail-box {
      margin-top: 15px;

      h4 {
        font-weight: bold;
        font-size: 22px;
      }

      a {
        color: $primary1;

        &:hover {
          color: $primary2;
        }
      }
    }

    &:hover {
      .img-box {
        background-color: $primary2;
      }
    }
  }

}

// end service section

// server section

.server_section {
  background: linear-gradient(110deg, transparent 25%, #020230 25%);
  padding: 55px 0;
  color: $white;

  .row {
    align-items: center;
  }

  .img-box {
    position: relative;

    img {
      width: 100%;
    }

    .play_btn {
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 65px;
      height: 65px;
      transform: translate(-50%, -50%);
      border: none;


      button {
        background-color: transparent;
        border: none;
        background-color: $primary1;
        color: $white;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        position: relative;
        z-index: 3;
        outline: none;
      }

      &:before,
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: $primary1;
        opacity: 1;
        border-radius: 100%;
        transform: translate(-50%, -50%);
      }

      &:before {
        z-index: 2;
        animation: before-animation 1500ms infinite;
      }

      &:after {
        z-index: 1;
        animation: after-animation 1500ms infinite;
      }
    }

    @keyframes before-animation {
      0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
      }

      100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
      }

    }

    @keyframes after-animation {
      0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
      }

      100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(2);
        opacity: 0;
      }

    }
  }

  .detail-box {
    a {
      @include hero_btn($primary2, $white, 10px, 45px, 5px);
      margin-top: 25px;
    }
  }
}

// end server section

// price section

.price_section {

  .price_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;

    .box {
      margin: 45px 15px 0 15px;

      .detail-box {
        width: 300px;
        padding: 40px 20px 15px 20px;
        background-color: #fff;
        border-radius: 25px;
        box-shadow: 0 0 5px 0 rgba($color: #000000, $alpha: .1);

        h2 {
          font-weight: bold;

          span {
            font-size: 3rem;
          }
        }

        h6 {
          text-transform: uppercase;
          color: $primary1;
          font-size: 20px;
          margin-bottom: 10px;
        }
      }

      .price_features {
        padding: 0;
        list-style-type: none;

        li {
          margin: 10px 0;
        }
      }

      .btn-box {
        margin-top: 25px;



        a {
          @include hero_btn($primary2, $white, 10px, 35px, 5px);
          border: none;

          &:hover {
            background-color: $primary1;
            color: $white;
          }
        }
      }

      &:hover {
        color: $white;

        .detail-box {
          background-color: $primary1;
        }

        h6 {
          color: $white;
        }

        a {
          background-color: $primary1;
          color: $white;
        }
      }
    }
  }
}


// end answer section

// client section

.client_section {
  padding-bottom: 45px;

  .heading_container {
    margin-bottom: 35px;
  }

  .box {
    display: flex;
    align-items: center;
    margin: 45px 0;

    .img-box {
      width: 125px;
      height: 125px;
      min-width: 125px;
      border-radius: 100%;
      overflow: hidden;
      margin-right: -65px;
      position: relative;

      img {
        width: 100%;
      }
    }

    .client_info {
      display: flex;
      justify-content: space-between;

      .client_name {
        h5 {
          font-weight: bold;
          color: $primary1;
          margin-bottom: 0;
          text-transform: uppercase;
        }

        h6 {
          margin-bottom: 0;
          color: $primary2;
          font-weight: normal;
          font-size: 14px;
          text-transform: uppercase;
        }
      }

      i {
        font-size: 24px;
        color: $primary1;
      }
    }

    p {
      margin-top: 25px;
    }

    .detail-box {
      background-color: $white;
      padding: 45px 45px 45px 85px;
      box-shadow: 0 0 5px 0 rgba($color: #000000, $alpha: 0.2);
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 50px;
    height: 50px;
    background-color: $primary1;
    opacity: 1;
    font-size: 28px;
    font-weight: bold;
    top: 50%;
    transform: translateY(-50%);
  }

  .carousel-control-prev {
    left: 0;
  }

  .carousel-control-next {
    right: 0;
  }
}

// end client section

// contact section
.contact_section {
  position: relative;

  .heading_container {
    margin-bottom: 45px;
  }

  .form_container {

    input {
      width: 100%;
      border: 1px solid #b0b0b0;
      height: 50px;
      margin-bottom: 25px;
      padding-left: 15px;
      background-color: transparent;
      outline: none;
      color: #101010;

      &::placeholder {
        color: #b0b0b0;
      }

      &.message-box {
        height: 120px;
      }
    }

    .btn_box {
      display: flex;
      justify-content: center;
    }

    button {
      border: none;
      text-transform: uppercase;
      @include hero_btn($primary2, $white, 15px, 65px, 0);
      font-weight: bold;
    }
  }


}

// end contact section

/* info section */
.info_section {
  background-color: #020230;
  color: $white;

  h4 {
    font-weight: 600;
    margin-bottom: 20px;
  }

  .info_contact {

    .contact_link_box {
      display: flex;
      flex-direction: column;

      a {
        margin: 5px 0;
        color: $white;

        i {
          margin-right: 5px;
        }

        &:hover {
          color: $primary2;
        }
      }
    }
  }

  .info_social {
    display: flex;
    margin-top: 20px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      border-radius: 100%;
      margin-right: 10px;
      font-size: 24px;

      &:hover {
        color: $primary2;
      }
    }
  }

  .info_links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    a {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      color: $white;

      img {
        margin-right: 10px;
      }

      &:hover,
      &.active {
        color: $primary2;
      }
    }
  }



  form {
    input {
      border: none;
      border-bottom: 1px solid $white;
      background-color: transparent;
      width: 100%;
      height: 45px;
      color: $white;
      outline: none;

      &::placeholder {
        color: $white;
      }
    }

    button {
      width: 100%;
      text-align: center;
      @include hero_btn($primary2, $white, 10px, 55px, 0);
      margin-top: 15px;
    }
  }
}

/* end info section */

/* footer section*/

.footer_section {
  position: relative;
  background-color: #020230;
  text-align: center;

  p {
    color: $white;
    padding: 20px 0;
    margin: 0;
    border-top: 1px solid $white;

    a {
      color: inherit;
    }
  }
}

// end footer section